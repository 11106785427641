.infoPage {
    height: 100dvh;
    background-color: #000000;
    color: #ffffff;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 4%;
    text-align: center;

    @media (min-width: 768px) {
        flex-direction: row;
        text-align: left;
    }
}

.infoPage__icon {
    margin-top: 4%;
}

.infoPage__icon svg  {
    @media (max-width: 768px){
        height: 200px; width: auto;
    }
}

.infoPage__content {

}

.infoPage__headline {
    font-weight: bold;
    font-size: clamp(24px, 8vw + 1rem, 120px);
    @media (max-width: 768px){margin-bottom: 0.15em;}
}

.infoPage__message {
    font-size: 22px;
    color: #f01e1c;
}

/* 404 page */

.infoPage404__message {
    text-align: center;
    text-transform: uppercase;
    font-size: clamp(24px, 8vw + 1rem, 72px);
}

.infoPage404__headline {
    font-size: clamp(50px, 44vw + 1rem, 400px);
}
